import { ref } from 'vue';
import { useOrderStore } from '@/store/orders';
import { storeToRefs } from 'pinia';
import { useOfferStore } from '@/store/offers';
import { useI18n } from 'vue-i18n';
import { handleDownload } from '@/helpers/util';
import { useSnackbarStore } from '@/store/snackbar';
import { useApi } from '@/composables/useApi';
import { useInvoiceStore } from '@/store/invoices';

/**
 * @typedef Document
 * @type {object}
 * @property {string} name - the document internal name.
 * @property {string} text - the document text / label.
 * @property {string} downloadName - the document's name on download.
 * @property {string} url - the document's download url.
 * @property {boolean} downloading - is the document in the process of downloading.
 * @property {boolean} cdn - is the document available from cdn.
 */

export function useInstallationDocuments() {
  const { t } = useI18n();
  const { showError } = useSnackbarStore();
  const { apiGet } = useApi();

  const orderStore = useOrderStore();
  const { getInitialOrderStatus, getOrderByID } = orderStore;
  const { order } = storeToRefs(orderStore);

  const offerStore = useOfferStore();
  const { getOfferByID } = offerStore;
  const { offer } = storeToRefs(offerStore);

  const invoiceStore = useInvoiceStore();
  const { getInvoiceByID, invoiceTypeName } = invoiceStore;
  const { invoice } = storeToRefs(invoiceStore);

  const documents = ref([]);

  async function getInstallationDocuments(installation) {
    documents.value = [];
    const InitialOrderStatus = await getInitialOrderStatus();

    if (installation.OfferID > 0) {
      if (installation.OfferID !== offer.value?.offerId) {
        await getOfferByID(installation.OfferID, installation.VersionNumber);
      }

      documents.value = [...documents.value, ...await getOfferDocs(offer.value)];
    }
    if (installation.OrderID > 0 && installation.OrderStatus > InitialOrderStatus) {
      if (installation.OrderID !== order.value?.orderId) {
        await getOrderByID(installation.OrderID);
      }

      documents.value = [...documents.value, ...await getOrderDocs(order.value)];
    }
    if (installation.Order_DeliveriesID > 0) {
      documents.value = [...documents.value, ...getDeliveryDocs(installation)];
    }
    if (installation.PrepaymentInvoiceID > 0) {
      documents.value = [...documents.value, ...getPrepaymentDocs(installation)];
    }
    if (installation.InvoiceID > 0) {
      if (installation.InvoiceID !== invoice.value?.InvoiceID) {
        await getInvoiceByID(installation.InvoiceID);
      }

      documents.value = [...documents.value, ...getInvoiceDocs(invoice.value)];
    }

    return documents.value;
  }

  async function getOfferDocs(offer) {
    const documents = [];
    documents.push({
      name: 'offer',
      downloading: false,
      text: t('offerDetail.offer'),
      downloadName: `${t('offerDetail.offer')}-${offer.offerId}.pdf`,
      url: `offers/${offer.offerId}/files/${offer.offerTypeId}?type=print&versionNumber=${offer.versionNumber}`,
    });

    return documents;
  }

  async function getOrderDocs(order) {
    const documents = [];

    documents.push({
      name: 'order',
      downloading: false,
      text: t('orderDetail.order'),
      downloadName: `${t('orderDetail.order')}-${order.orderId}.pdf`,
      url: `orders/${order.orderId}/files/${order.offerTypeId}?type=print`,
    });

    // doc mise en service
    if (['RESA', 'AIESH', 'REW', 'AIESH'].includes(order?.netOperator)) {
      documents.push({
        cdn: false,
        text: t('netoperator_form'),
        name: 'declaration-RGIE',
        downloadName: `${t('netoperator_form')}-${order.orderId}.pdf`,
        url: `files/sales/orders/${order.orderId}/declaration-RGIE`,
      });
    }
    // fotos not fluvius
    if (['RESA', 'AIESH', 'REW', 'AIESH'].includes(order?.netOperator)) {
      documents.push({
        cdn: false,
        text: t('netoperator_pictures'),
        name: 'picturesReference',
        downloadName: `${t('netoperator_pictures')}-${order.orderId}.pdf`,
        url: `files/sales/orders/${order.orderId}/picturesReference`,
      });
    }
    // keuringattest
    if (order?.keuringCertificate) {
      documents.push({
        cdn: true,
        url: order?.keuringCertificate,
        text: t('netoperator_inspectionReport'),
        name: 'keuringCertificate',
      });
    }
    // wiring voor keuring
    if (order?.hasWiringDiagram && order?.wiringDiagramKeuring !== '') {
      documents.push({
        cdn: false,
        text: t('netoperator_electrical_schema'),
        name: 'wiringDiagramDoc',
        downloadName: `${t('netoperator_electrical_schema')}-${order.orderId}.pdf`,
        url: `files/sales/orders/${order.orderId}/wiringDiagramDoc`,
      });
    }
    // wiring na keuring
    if (order?.wiringDiagramKeuring !== '') {
      documents.push({
        cdn: true,
        url: order?.wiringDiagramKeuring,
        text: t('netoperator_electrical_inspection'),
        name: 'wiringDiagramKeuring',
      });
    }
    // hulp bij melding
    if (['ORES', 'INFRAX', 'EANDIS'].includes(order?.netOperator)) {
      documents.push({
        cdn: false,
        text: t('netoperator_installationHelp'),
        name: 'PVHelpByRegistration',
        downloadName: `${t('netoperator_installationHelp')}-${order.orderId}.pdf`,
        url: `files/sales/orders/${order.orderId}/PVHelpByRegistration`,
      });
    }
    // ichoosr
    if (order.Order_DeliveriesID && order.HasExternalPrepayment) {
      documents.push({
        name: 'orderIchoosrOpleverdoc',
        downloading: false,
        text: t('iChoosr.document_name'),
        downloadName: `${t('iChoosr.document_name')}-${order.orderId}.pdf`,
        url: `deliveries/${order.Order_DeliveriesID}/iChooserFiles`,
      });
    }

    return documents;
  }

  function getDeliveryDocs(installation) {
    const documents = [];
    documents.push({
      name: 'delivery',
      downloading: false,
      text: t('deliveryDetail.delivery'),
      downloadName: `${t('deliveryDetail.delivery')}-${installation.Order_DeliveriesID}.pdf`,
      url: `deliveries/${installation.Order_DeliveriesID}/files/${installation.Offer_TypeID}?type=print`,
    });
    return documents;
  }

  function getPrepaymentDocs(installation) {
    const documents = [];
    documents.push({
      name: 'prepayment',
      downloading: false,
      text: t('invoiceDetail.prepaymentInvoice'),
      downloadName: `${t('invoiceDetail.prepaymentInvoice')}-${installation.PrepaymentInvoiceID}.pdf`,
      url: `invoices/${installation.PrepaymentInvoiceID}/files/${installation.Offer_TypeID}?type=print`,
    });
    return documents;
  }

  function getInvoiceDocs(invoice) {
    const documents = [];
    const text = invoiceTypeName(invoice);
    documents.push({
      name: 'invoice',
      downloading: false,
      text,
      downloadName: `${text}-${invoice.invoiceNrPrefix}${invoice.invoiceNr}.pdf`,
      url: `invoices/${invoice.invoiceId}/files/${invoice.offerTypeId}?type=print`,
    });
    return documents;
  }

  async function getInstallationDocumentsByName(installation, docNames) {
    await getInstallationDocuments(installation);

    return documents.value.reduce((acc, doc) => {
      if (docNames.includes(doc.name)) {
        acc.push(doc);
      }

      return acc;
    }, []);
  }

  async function getOrderDocByName(order, docName) {
    const documents = await getOrderDocs(order);

    return documents.find((doc) => doc.name === docName);
  }

  /**
   * handle downloading of document
   * @param {Document} _document
   * @returns {Promise<void>}
   */
  async function download(_document) {
    _document.downloading = true;
    try {
      if (_document.cdn) {
        await downloadFromCDN(_document);
      } else {
        await downloadFromApi(_document);
      }
    } catch (error) {
      console.log(error);
      showError(t('fileError'));
    }
    _document.downloading = false;
  }

  /**
   * Open Document in tab from cdn url
   * @param {Document} _document
   * @returns {Promise<void>}
   */
  async function downloadFromCDN(_document) {
    window.open(_document.url, '_blank').focus();
  }

  /**
   * Open Document in tab from cdn url
   * @param {Document} _document
   * @returns {Promise<void>}
   */
  async function downloadFromApi(_document) {
    const response = await apiGet(_document.url, 'blob');

    if (response.status === 200) {
      handleDownload(_document.downloadName, response.data);
    } else {
      showError(t('fileError'));
    }
  }

  return {
    getInstallationDocuments,
    getOrderDocByName,
    download,
    getInstallationDocumentsByName,
  };
}
