import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getApiRoot } from '@/helpers/api';
import { getSubdomain } from '@/helpers/util';
import { localStorageKeys, removeLocalstorageItem } from '@/helpers/localStorage';
import apiClient from '@plugins/api-client';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { getKeycloak } from '@/helpers/keycloak';
import { useMonitoringRealTimeStore } from '@/store/monitoringRealTime';
import moment from 'moment';

export const useUserStore = defineStore('userStore', () => {
  const userLoggedIn = ref(false);
  const user = ref();
  const i18n = useI18n();

  const { unsubscribeBroadcast } = useMonitoringRealTimeStore();

  const userLocale = computed(() => user.value?.locale);

  async function updateUser() {
    try {
      const response = await apiClient.get(`${getApiRoot()}/auth/me`, {
        params: { subDomain: getSubdomain() },
        headers: {
          Authorization: `Bearer ${(await getKeycloak()).token}`,
        },
      });

      const data = response.data?.data;
      if (data === undefined) {
        console.log('Api did not return customer data.');
        return;
      }

      user.value = {
        id: data.customerIds,
        email: data.email,
        locale: data.languageCode.toLowerCase(),
      };
      userLoggedIn.value = true;
      i18n.locale.value = user.value.locale;
      moment.locale(user.value.locale);
    } catch (e) {
      console.log('Api did not return customer data.' + e);
    }
  }

  async function logoutUser() {
    removeLocalstorageItem(localStorageKeys.user);
    removeLocalstorageItem(localStorageKeys.installations);

    Object.keys(localStorage)
      .filter((key) => key.startsWith(localStorageKeys.monitoring))
      .forEach((key) => localStorage.removeItem(key));

    unsubscribeBroadcast();

    userLoggedIn.value = false;
    user.value = undefined;

    const keycloak = await getKeycloak();
    keycloak.logout();

    router.push({ path: '/login' });
  }

  return {
    user,
    userLoggedIn,
    userLocale,
    updateUser,
    logoutUser,
  };
});
