import { defineStore } from 'pinia';
import { ref, shallowRef } from 'vue';
import router from '@/router';
import { useInstallationStore } from '@/store/installations';

export const useTopNavBarStore = defineStore('topNavBarStore', () => {
  const { getInstallationRouteByResourceID } = useInstallationStore();

  const leftNav = ref('back');
  const navTitle = ref('');
  const rightNav = ref('');
  const downloadDocument = ref({});
  const leftNavCustomIcon = ref();
  const modalComponent = shallowRef();
  const modalActive = ref(false);

  const backFunction = ref(() => router.go(-1));

  function setLeftNav(leftNavButton) {
    leftNav.value = leftNavButton;
  }

  function setLeftNavCustomIcon(icon) {
    leftNavCustomIcon.value = icon;
  }

  function setNavTitle(title) {
    navTitle.value = title;
  }

  function setRightNav(rightNavButtons) {
    rightNav.value = rightNavButtons;
  }

  function setBackFunction(newBackFunction) {
    backFunction.value = newBackFunction;
  }

  function resetBackFunction() {
    setBackFunction(() => router.go(-1));
  }

  function setDownloadDocument(newDownloadDocument) {
    downloadDocument.value = newDownloadDocument ?? {};
  }

  function setModal(component) {
    modalComponent.value = component;
  }

  function toggleModal() {
    modalActive.value = !modalActive.value;
  }

  /**
   * @param {object} data
   * @param {string} [data.title]
   * @param {object} [data.left]
   * @param {object} [data.right]
   * @returns {Promise<void>}
   */
  async function initTopBar(data) {
    const { title, left, right } = data;
    await initNavLeft(left);
    setNavTitle(title);
    initNavRight(right);
  }

  /**
   *
   * @param {object} data
   * @param {string} [data.action]
   * @param {object} [data.backFunction]
   * @returns {Promise<void>}
   */
  async function initNavLeft(data = {}) {
    const { action, backAction, customIcon } = data;

    setLeftNav(action ?? '');
    setLeftNavCustomIcon(customIcon);

    if (action === 'back') {
      await initBackFunction(backAction);
    }
  }

  /**
   *
   * @param {object} data
   * @param {string} [data.action]
   * @param {object} [data.document]
   */
  function initNavRight(data = {}) {
    const { action, document } = data;
    setRightNav(action ?? '');

    if (document) {
      setDownloadDocument(document);
    }
  }

  /**
   *
   * @param {object} data
   * @param {string} [data.callback] A custom back function
   * @param {string} [data.to] A vue router route
   * @param {string} [data.resource] This function will try to find an app resource to navigate to
   * @returns {Promise<void>}
   */
  async function initBackFunction(data = {}) {
    if (history.state.backPath) {
      setBackFunction(() => router.push({ path: history.state.backPath }));
    } else if (data.callback) {
      setBackFunction(data.callback);
    } else if (data.to) {
      setBackFunction(() => router.push(data.to));
    } else if (data.resource) {
      const { idName, id } = data.resource;

      const to = await getInstallationRouteByResourceID(idName, id);

      setBackFunction(() => router.push(to));
    } else {
      resetBackFunction();
    }
  }

  return {
    leftNav, leftNavCustomIcon, setLeftNav,
    navTitle, setNavTitle,
    rightNav, setRightNav,
    backFunction, setBackFunction,
    downloadDocument, setDownloadDocument,
    modalComponent, modalActive, setModal, toggleModal,
    initTopBar,
  };
});
