import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApi } from '@/composables/useApi';
import {
  getLocalstorageItem,
  localStorageKeys,
  setLocalstorageItem,
} from '@/helpers/localStorage';
import { useRoute, useRouter } from 'vue-router';

export const useMonitoringGeneralStore = defineStore('monitoringGeneralStore', () => {
  const { t } = useI18n();
  const { apiGet } = useApi();

  const route = useRoute();
  const router = useRouter();

  const loading = ref(false);
  const lastUpdated = ref(null);
  const devices = ref([]);
  const currentDevice = ref();
  const monitoringEnabled = ref(getLocalstorageItem(localStorageKeys.monitoring));

  const config = computed(() => ({
    home: {
      label: t('monitoring.home'),
      color: 'home',
      chart: {
        fill: true,
      },
    },
    grid: {
      label: t('monitoring.grid'),
      color: 'grid',
      icon: 'mdi-transmission-tower',
      chart: {
        fill: true,
      },
    },
    ev: {
      label: t('monitoring.ev'),
      color: 'ev',
      icon: 'mdi-ev-station',
      chart: {
        fill: true,
      },
    },
    solar: {
      label: t('monitoring.solar'),
      color: 'solar',
      icon: 'mdi-solar-power-variant-outline',
      chart: {
        fill: true,
      },
    },
    battery: {
      label: t('monitoring.battery'),
      color: 'battery',
      icon: 'mdi-battery-medium',
      chart: {
        fill: true,
      },
    },
    batteryCharge: {
      label: t('monitoring.charge'),
      color: 'charge',
    },
    batteryDischarge: {
      label: t('monitoring.discharge'),
      color: 'discharge',
    },
    gridConsume: {
      label: t('monitoring.consume'),
      color: 'consume',
    },
    gridInject: {
      label: t('monitoring.inject'),
      color: 'inject',
    },

    priceConsumption: {
      label: t('monitoring.priceConsumption'),
      color: 'priceConsumption',
      icon: 'mdi-transmission-tower-export',
      chart: {
        yAxisID: 'y1',
        fill: false,
        borderDash: [3, 3],
      },
    },
    priceInjection: {
      label: t('monitoring.priceInjection'),
      color: 'priceInjection',
      icon: 'mdi-transmission-tower-import',
      chart: {
        yAxisID: 'y1',
        fill: false,
        borderDash: [3, 3],
      },
    },

    insights: {
      earnings: {
        label: t('monitoring.earnings'),
        color: 'earnings',
        icon: 'mdi-home-lightning-bolt-outline',
      },

      savings: {
        label: t('monitoring.savings'),
        color: 'savings',
        icon: 'mdi-scale-balance',

        solar: {
          label: t('monitoring.solar'),
          color: 'solarControl',
        },
        battery: {
          label: t('monitoring.batteryControl'),
          color: 'batteryControl',
        },
        tariff: {
          label: t('monitoring.tariff'),
          color: 'tariffChoice',
        },
        ev: {
          label: t('monitoring.ev'),
          color: 'evControl',
        },
        energy: {
          label: t('monitoring.energy'),
          color: 'energyControl',
        },
      },
    },
  }));

  const currentDeviceId = computed(() => currentDevice.value?.deviceId);

  const getDevices = async () => {
    // no need to check for new devices more than once every 30 minutes
    if (loading.value || (lastUpdated.value && lastUpdated.value < Date.now() - 30 * 60 * 1000)) {
      return;
    }

    loading.value = true;

    const result = await apiGet('monitoring/getDevices');

    devices.value = result;

    if (route.query.deviceId && route.query.deviceId !== currentDeviceId.value) {
      selectDeviceById(route.query.deviceId);
    }

    if (!currentDevice.value && devices.value) {
      selectDevice(devices.value[0]);
    }

    setMonitoringEnabled(devices.value?.length > 0);
    lastUpdated.value = Date.now();
    loading.value = false;

    return result;
  };

  const selectDevice = (device) => {
    currentDevice.value = device;

    router.push({
      path: route.path,
      query: {
        deviceId: device.deviceId,
      },
    });
  };

  const selectDeviceById = (deviceId) => {
    const device = devices.value.find((device) => device.deviceId === deviceId);

    if (device) {
      selectDevice(device);
    }
  };

  const setMonitoringEnabled = (enabled) => {
    setLocalstorageItem(localStorageKeys.monitoring, enabled);
    monitoringEnabled.value = enabled;
  };

  return {
    loading,
    monitoringEnabled,
    devices,
    currentDevice,
    currentDeviceId,
    config,
    getDevices,
    selectDevice,
    selectDeviceById,
  };
});
